<template>
  <div
    class="popover"
    :class="device+ '-popover'"
  >
    <div class="header">
      <img
        src="@assets/icon_indicateur_ajout.svg"
        alt="extend menu"
      >
      <h2 v-t="'router.views.Catalog.addIndicatorHeader'" />
      <div
        class="cross"
        @click="close"
      >
        <img
          src="@assets/cross_white.png"
          alt="close"
        >
      </div>
    </div>
    <LibraryIndicatorList
      :indicator-list="indicatorList"
      :hidden-list="category.indicatorList"
      :admin-mode="false"
      class="list"
      @selectIndicator="addIndicator"
    />
  </div>
</template>

<script>
import LibraryIndicatorList from '@components/Indicator/Library/IndicatorList'

export default {
  name: 'LibraryPopover',
  components: {
    LibraryIndicatorList
  },
  inject: ['mq'],
  props: {
    catalogId: {
      type: String,
      required: true
    },
    category: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    indicatorList () {
      return this.$store.getters['library/sortedIndicatorList'] || []
    },
    isProfileCategory () {
      const profileCatalog = this.$store.state.catalog.profileCatalog
      return profileCatalog.categoryList.map(item => item.categoryId).indexOf(this.category.categoryId) !== -1
    }
  },
  mounted () {
    if (this.$store.state.library.indicatorList === undefined) {
      this.$store.dispatch('library/initIndicatorList')
    }
    window.addEventListener('click', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    addIndicator (indicator) {
      this.$store.dispatch('catalog/addIndicatorInCategory', {
        catalogId: this.catalogId,
        categoryId: this.category.categoryId,
        position: this.category.indicatorList.length + 1,
        isPublic: false,
        indicator
      })
    },
    clickOutside (e) {
      const self = this
      if (self.$el && !self.$el.contains(e.target)) {
        this.close()
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.popover {
  background-color: #fff;
  height: 400px;
  width: 530px;
  overflow: auto;
  box-shadow: 0 2px 2px 0 #777676;
  border: 1px solid #777676;
  border-radius: 5px;
  cursor: default;
  z-index: 1;
}

.phone-popover {
  height: 400px;
  width: 300px;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;

  h2 {
    margin: 7px 0 0 14px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
  }

  img {
    height: 35px;
  }

  .cross {
    position: absolute;
    top:-5px;
    right: 15px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    cursor: pointer;
    background-color: $light-grey-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color-cadyco-dark-text;
    }

    img {
      height: 12px;
    }
  }
}

.list {
  margin: 0 10px;
}
</style>
